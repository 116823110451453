<template>
  <v-navigation-drawer app fixed permanent left dark>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-img
          style="filter: brightness(10000)"
          :src="logo"
          height="50"
          contain
          class="my-4"
        ></v-img>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list>
      <v-list-item-group v-model="currMenu">
        <v-list-item v-for="item in items" :key="item.title" :href="item.link">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template>
      <div class="mx-4 mt-16">
        <v-btn block> Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      currMenu: 0,
      logo: require("../assets/logo-1.png"),
      items: [
        { title: "Home", icon: "mdi-home-city", link: "/dashboard" },
        { title: "BHVT", icon: "mdi-bitcoin", link: "/CoinPanel_BHVT" },
        {
          title: "Customer",
          icon: "mdi-account-group-outline",
          link: "/userPanel",
        },
        {
          title: "Deposit",
          icon: "mdi-cash-plus",
          link: "/DepositPanel",
        },
        {
          title: "withdrawal",
          icon: "mdi-cash-minus",
          link: "/WithdrawalPanel",
        }
     /*    },
        {
          title: "send-email",
          icon: "mdi-email-edit",
          link: "/EmailPanel",
        },
        {
          title: "MailBox",
          icon: "mdi-email",
          link: "/MailBox",
        },
        {
          title: "Staking",
          icon: "mdi-currency-usd",
          link: "/stakingProfit",
        }, */
      ],
    };
  },
  methods: {
    getCurrMenu: function () {
      switch (this.$router.currentRoute.name) {
        case "Dashboard":
          this.currMenu = 0;
          break;
        case "CoinPanel_BHVT":
          this.currMenu = 1;
          break;
        case "UserPanel":
          this.currMenu = 2;
          break;
        case "DepositPanel":
          this.currMenu = 3;
          break;
        case "WithdrawalPanel":
          this.currMenu = 4;
          break;
        case "EmailPanel":
          this.currMenu = 5;
          break;
        case "MailBox":
          this.currMenu = 6;
          break;
        case "StakingProfit":
          this.currMenu = 7;
          break;
        default:
      }
    },
  },
  created() {
    this.getCurrMenu();
  },
};
</script>
